.results-panel-border {
  border: none;
}
.panel-title-align {
  text-align: center;
}
.results-panel-body {
  padding: 0 15px;
  text-align: center;
}

.result {
  border-radius: 5px;
  padding: 10px;
}
/* .no-results-found {
  background-color: white;
  color: #777;
  font-size: 34px;
  line-height: 2;
} */


.actions-block {
  padding-left: 0;
  margin: -7px 0;
}
.edit-button {
  margin-right: 5px;
}