body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  border-radius: 0 !important;
}
.App {
  /* text-align: center; */
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
}

.login-panel-title {
  background-color: white;
  color: #777;
  font-size: 34px;
  margin-left: 30px;
}

.login-form-group {
  margin-top: 50px
}
.loader {
  margin: 20px auto;
  width: 100%;
  left: 0;
  text-align: center;
  position: absolute;
}
.my-modal-dialog {
  padding-top: 10%;
  background-color: rgba(0,0,0,.5);
}
.red {
  color: red;
}
.match {
  border-radius: 5px;
  padding: 10px;
}
.match:nth-child(even){background-color: #f2f2f2;}
.match:nth-child(odd){background-color: #fff;}
.match:hover {background-color: #ddd;}

.actions-dropdown {
  padding-left: 0;
  margin: -7px 0;
}

.matches-panel {
  border: none;
}
.panel-title-align {
  text-align: center;
}
.matches-panel-body {
  padding: 0 15px;
  text-align: center;
}

.no-results-found {
  background-color: white;
  color: #777;
  font-size: 34px;
  line-height: 2;
}

.filter-panel {
  margin-top: -15px;
}

.filter-button {
  margin-left: 10px;
}

.filter-label {
  background-color: #f5f5f5;
  color: #777;
  font-size: 14px;
}

.filter-search-icon {
  margin-top: 10px;
  margin-left: -50px;
}
.odds-panel {
  margin-bottom: 5px;
}
.odds-panel-head {
  background-color: #d0e7d4;
  padding: 5px;
}

.odds-button-margin-right {
  margin-left: 10px;
}
.odds-button-icon {
  margin-left: 5px;
  cursor: pointer;
}

.odds-panel-body {
  background-color: #f5f5f5;
  padding: 5px;
}

.no-results-found{
  background-color: white;
  color: #777;
  font-size: 34px;
}

.filter-panel {
  margin-top: -15px;
}

.filter-panel-label {
  background-color: #f5f5f5;
  color: #777;
  font-size: 14px;
}

.filter-search-icon {
  margin-top: 10px;
  margin-left: -50px;
}
.edit-page-title {
  text-align: right;
  margin-right: 30px;
}

.edit-page-label {
  background-color: #fff;
  color: #555
}
.save-button {
  margin-right: 50px;
}

.buttons-block {
  padding-left: 0;
  margin: -7px 0;
}

.edit-button {
  margin-right: 5px;
}
@charset "UTF-8";
.result-search {
  margin-bottom: 22px; }
  .result-search .show-detailed a {
    color: #666;
    -webkit-transition: color 0.2s ease-in;
    transition: color 0.2s ease-in;
    padding: 7px;
    cursor: pointer;
    font-size: 14px; }
    .result-search .show-detailed a:hover {
      color: #444;
      text-decoration: none; }
  .result-search .show-detailed i {
    margin-right: 7px; }
  .result-search .detailed .from::after {
    content: '\279E';
    position: absolute;
    right: -5px;
    top: 7px; }

.results-panel-border {
  border: none;
}
.panel-title-align {
  text-align: center;
  /* font-size: 11px; */
}
.results-panel-body {
  padding: 0 15px;
  text-align: center;
}

.result {
  border-radius: 5px;
  padding: 10px;
}
/* .no-results-found {
  background-color: white;
  color: #777;
  font-size: 34px;
  line-height: 2;
} */


.actions-block {
  padding-left: 0;
  margin: -7px 0;
}
.edit-button {
  margin-right: 5px;
}
.save-button {
  margin-right: 50px
}

.edit-result-dropdown {
  min-width: 160px;
}
.save-button {
  margin-right: 50px
}
.red {
  color: red;
}
.match {
  border-radius: 5px;
  padding: 10px;
}
.match:nth-child(even){background-color: #f2f2f2;}
.match:nth-child(odd){background-color: #fff;}
.match:hover {background-color: #ddd;}

.actions-dropdown {
  padding-left: 0;
  margin: -7px 0;
}

.matches-panel {
  border: none;
}
.panel-title-align {
  text-align: center;
}
.matches-panel-body {
  padding: 0 15px;
  text-align: center;
}

.no-results-found {
  background-color: white;
  color: #777;
  font-size: 34px;
  line-height: 2;
}

.filter-panel {
  margin-top: -15px;
}

.filter-button {
  margin-left: 10px;
}

.filter-label {
  background-color: #f5f5f5;
  color: #777;
  font-size: 14px;
}

.filter-search-icon {
  margin-top: 10px;
  margin-left: -50px;
}
.buttons-block {
  text-align: right;
  padding: 0;
}

.buttons-margin-right {
  margin-right: 5px;
}

.odds-panel-border {
  border: none;
}
.save-button {
  margin-right: 50px
}
.save-button {
  margin-right: 50px
}
.save-button {
  margin-right: 50px
}
.complete-block {
  padding-left: 0;
  margin: -7px 0;
}
.complete-button {
  margin-right: 5px;
}

.payments-panel {
  margin-top: -15px;
}
.panel-title-align {
  text-align: center;
}

.payments-panel-label {
  background-color: #f5f5f5;
  color: #777;
  font-size: 14px;
}
.income-block {
  padding-top: 7px;
}
.income-label {
  background-color: #f5f5f5;
  color: #e91e63;
  font-size: 14px;
}
.payments-table-header {
  border: none
}

.payments-table-content {
  padding: 0 15px;
  text-align: center;
  
}

.no-payments-found {
  background-color: white;
  color: #777;
  font-size: 34px;
  line-height: 2;
}
.users-panel-border {
  border: none;
}

.no-results-found {
  background-color: white;
  color: #777;
  font-size: 34px;
  line-height: 2;
}
.panel-title-align {
  text-align: center;
}
.users-panel-body {
  padding: 0 15px;
  text-align: center;
}

.users-filter-panel {
  margin-top: -15px;
}
.users-filter-label {
  background-color: #f5f5f5;
  color: #777;
  font-size: 14px;
}
.users-count-block {
  padding-top: 7px;
}

.users-count-label {
  background-color: #f5f5f5;
  color: rgb(51, 122, 183);
  font-size: 14px;
  cursor: pointer;
}

.filter-search-icon {
  margin-top: 10px;
  margin-left: -50px;
}

@charset "UTF-8";
.result-search {
  margin-bottom: 22px; }
  .result-search .show-detailed a {
    color: #666;
    -webkit-transition: color 0.2s ease-in;
    transition: color 0.2s ease-in;
    padding: 7px;
    cursor: pointer;
    font-size: 14px; }
    .result-search .show-detailed a:hover {
      color: #444;
      text-decoration: none; }
  .result-search .show-detailed i {
    margin-right: 7px; }
  .result-search .detailed .from::after {
    content: '\279E';
    position: absolute;
    right: -5px;
    top: 7px; }

.results-panel-border {
  border: none;
}
.panel-title-align {
  text-align: center;
}
.results-panel-body {
  padding: 0 15px;
  text-align: center;
}

.result {
  border-radius: 5px;
  padding: 10px;
}
/* .no-results-found {
  background-color: white;
  color: #777;
  font-size: 34px;
  line-height: 2;
} */


.actions-block {
  padding-left: 0;
  margin: -7px 0;
}
.edit-button {
  margin-right: 5px;
}
.save-button {
  margin-right: 50px
}

.edit-result-dropdown {
  min-width: 160px;
}
.buttons-block {
  padding-left: 0;
  margin: -7px 0;
}

.edit-button {
  margin-right: 5px;
}
.save-button {
  margin-right: 50px
}
.save-button {
  margin-right: 50px
}
.notfound-block {
  text-align: center;
}
.no-results-found {
  background-color: white;
  color: #777;
  font-size: 34px;
  margin-top: 10%;
}
