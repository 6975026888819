.odds-panel {
  margin-bottom: 5px;
}
.odds-panel-head {
  background-color: #d0e7d4;
  padding: 5px;
}

.odds-button-margin-right {
  margin-left: 10px;
}
.odds-button-icon {
  margin-left: 5px;
  cursor: pointer;
}

.odds-panel-body {
  background-color: #f5f5f5;
  padding: 5px;
}

.no-results-found{
  background-color: white;
  color: #777;
  font-size: 34px;
}

.filter-panel {
  margin-top: -15px;
}

.filter-panel-label {
  background-color: #f5f5f5;
  color: #777;
  font-size: 14px;
}

.filter-search-icon {
  margin-top: 10px;
  margin-left: -50px;
}