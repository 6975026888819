.complete-block {
  padding-left: 0;
  margin: -7px 0;
}
.complete-button {
  margin-right: 5px;
}

.payments-panel {
  margin-top: -15px;
}
.panel-title-align {
  text-align: center;
}

.payments-panel-label {
  background-color: #f5f5f5;
  color: #777;
  font-size: 14px;
}
.income-block {
  padding-top: 7px;
}
.income-label {
  background-color: #f5f5f5;
  color: #e91e63;
  font-size: 14px;
}
.payments-table-header {
  border: none
}

.payments-table-content {
  padding: 0 15px;
  text-align: center;
  
}

.no-payments-found {
  background-color: white;
  color: #777;
  font-size: 34px;
  line-height: 2;
}