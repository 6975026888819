.buttons-block {
  text-align: right;
  padding: 0;
}

.buttons-margin-right {
  margin-right: 5px;
}

.odds-panel-border {
  border: none;
}