.edit-page-title {
  text-align: right;
  margin-right: 30px;
}

.edit-page-label {
  background-color: #fff;
  color: #555
}
.save-button {
  margin-right: 50px;
}