.users-panel-border {
  border: none;
}

.no-results-found {
  background-color: white;
  color: #777;
  font-size: 34px;
  line-height: 2;
}
.panel-title-align {
  text-align: center;
}
.users-panel-body {
  padding: 0 15px;
  text-align: center;
}

.users-filter-panel {
  margin-top: -15px;
}
.users-filter-label {
  background-color: #f5f5f5;
  color: #777;
  font-size: 14px;
}
.users-count-block {
  padding-top: 7px;
}

.users-count-label {
  background-color: #f5f5f5;
  color: rgb(51, 122, 183);
  font-size: 14px;
  cursor: pointer;
}

.filter-search-icon {
  margin-top: 10px;
  margin-left: -50px;
}