@charset "UTF-8";
.result-search {
  margin-bottom: 22px; }
  .result-search .show-detailed a {
    color: #666;
    transition: color 0.2s ease-in;
    padding: 7px;
    cursor: pointer;
    font-size: 14px; }
    .result-search .show-detailed a:hover {
      color: #444;
      text-decoration: none; }
  .result-search .show-detailed i {
    margin-right: 7px; }
  .result-search .detailed .from::after {
    content: '➞';
    position: absolute;
    right: -5px;
    top: 7px; }
